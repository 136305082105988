export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  colors: {
    text: '#333333',
    background: '#ffffff',
    primary: '#3375C6',
    accent: '#F0EEEB',
    dimGray: '#707070',
    SVT: '#5B57ED',
    BBC: '#EE8843',
    'Al-Jazeera': '#60C0C4',
    modes: {
      dark: {
        text: '#fff',
        background: '#060606',
        accent: '#191919',
        primary: '#33ccff',
      },
    },
  },
  text: {
    default: {
      color: 'text',
      fontSize: 3,
    },
    dim: {
      color: 'dimGray',
      fontSize: 1,
    },
    dimHeader: {
      color: 'dimGray',
      fontSize: 3,
      textTransform: 'capitalize',
    },
    pageTitle: {
      margin: 0,
      fontSize: [4, 5],
    },
  },
  images: {
    logo: {
      width: [36, 48],
      height: [36, 48],
    },
  },
  links: {
    pageTitle: {
      fontWeight: 'bold',
      color: 'text',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'none',
      },
      ':visited': {
        color: 'text',
      },
    },
  },
  buttons: {
    link: {
      color: 'background',
      backgroundColor: 'transparent',
      border: 'none',
      background: 'none',
      boxShadow: 'none',
      padding: 0,
      display: 'flex',
    },
  },
  styles: {
    root: {
      font:
        '100%/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamily: 'body',
      backgroundColor: 'accent',
      lineHeight: 1.5,
      margin: 0,
    },
    h1: {
      fontFamily: 'body',
    },
    h3: {
      fontFamily: 'body',
      marginBottom: 0,
      marginTop: 0,
      lineHeight: 1.3,
    },
    a: {
      fontFamily: 'body',
      color: 'text',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
      ':visited': {
        color: 'grey',
      },
    },
  },
};
